// <div data-controller="components--csv-export">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";

export default class extends Controller {
  static values = {
    params: Object, // Expecting JSON array for data
    filename: String, // Filename for the CSV file
  };

  export(event) {
    event.preventDefault();
    const jsonData = this.paramsValue.data;
    const filename = this.filenameValue || "통계 내역"; // Default to "export.csv" if no filename is provided

    try {
      const isMultiData =
          Array.isArray(jsonData) &&
          typeof jsonData[0] === "object" &&
          jsonData[0].data;

      if (isMultiData) {
        this.exportMultiData(jsonData, filename);
      } else {
        this.exportSingleData(jsonData, filename);
      }
    } catch (error) {
      console.error("Invalid JSON data provided:", error);
      alert("데이터 처리 중 오류가 발생했습니다.");
    }
  }

  exportSingleData(data, filename) {
    const csvContent = ["Key,Value"]; // Header row

    // Convert Object to CSV rows
    if (typeof data === "object") {
      Object.entries(data).forEach(([key, value]) => {
        csvContent.push(`${this.escapeCSVValue(key)},${value}`);
      });
    } else {
      throw new Error("Unsupported data format for exportSingleData");
    }

    this.downloadCSV(csvContent.join("\n"), `${filename}.csv`);
  }

  exportMultiData(data, filename) {
    const csvContent = [];
    const headers = ["Key"];

    // Collect dataset names for the header
    data.forEach((dataset) => {
      headers.push(this.escapeCSVValue(dataset.name));
    });

    csvContent.push(headers.join(",")); // Add header row

    // Collect all unique keys
    const allKeys = new Set();
    data.forEach((dataset) => {
      if (typeof dataset.data === "object") {
        Object.keys(dataset.data).forEach((key) => allKeys.add(key));
      }
    });

    // Populate rows with keys and values
    allKeys.forEach((key) => {
      const row = [this.escapeCSVValue(key)];
      data.forEach((dataset) => {
        if (typeof dataset.data === "object") {
          row.push(dataset.data[key] || 0); // Fill with 0 if no match
        } else {
          row.push(0);
        }
      });
      csvContent.push(row.join(","));
    });

    this.downloadCSV(csvContent.join("\n"), `${filename}.csv`);
  }

  // Escapes values for CSV (adds quotes if necessary)
  escapeCSVValue(value) {
    if (typeof value === "string" && (value.includes(",") || value.includes('"'))) {
      return `"${value.replace(/"/g, '""')}"`; // Escape quotes by doubling them
    }
    return value;
  }

  downloadCSV(csvContent, fileName) {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
